import React, { useCallback, useMemo } from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import uniqWith from 'lodash/uniqWith';

import { NanoID, UUID } from '../../../../../../../types';
import { UserNanoID } from '../../../../../../users/usersTypes';

import { CreateSmartContractShareFormUsers } from '../../CreateSmartContractShareForm.types';
import { CheckableUsersListItemUserType } from '../../../../../../common/modalButtons/InviteUsersModalButton/components/InviteUsersModalBody/components/CheckableUsersListItem';

import { InviteUsersModalBody } from '../../../../../../common/modalButtons/InviteUsersModalButton/components/InviteUsersModalBody';
import { UserAvatarLinkUser } from '../../../../../../common/helpers/UserAvatarLink';

interface CreateSmartContractShareFormChangeUsersProps {
  invitedUserUuids?: UUID[];
  onChange: (users: CreateSmartContractShareFormUsers) => void;
  users: CreateSmartContractShareFormUsers;
}

function CreateSmartContractShareFormChangeUsers({
  onChange,
  users,
  invitedUserUuids
}: CreateSmartContractShareFormChangeUsersProps) {
  const filteredUsers = useMemo(() => {
    const clients =
      map(users.clients, (client) => ({
        ...client,
        id: client.userId,
        nanoId: client.nanoId as UserNanoID,
        currentTeam: {
          nanoId: client.currentTeam?.nanoId as NanoID
        },
        client: true
      })) || [];
    const workers =
      map(users.workers, (worker) => ({
        ...worker,
        id: worker.userId,
        nanoId: worker.nanoId as UserNanoID,
        currentTeam: {
          nanoId: worker.currentTeam?.nanoId as NanoID
        },
        client: false
      })) || [];

    return [...clients, ...workers];
  }, [users.clients, users.workers]);

  const handleDeselectUser = useCallback<
    (selectedUser: CheckableUsersListItemUserType) => void
  >(
    (selectedUser) => {
      const newUsers = filter(filteredUsers, (m) => m.id !== selectedUser.id);

      const clients = filter(newUsers, (user) => user.client);

      const workers = filter(newUsers, (user) => !user.client);

      onChange({
        clients: map(clients, (client) => ({
          ...client,
          userId: client.id
        })),
        workers: map(workers, (worker) => ({
          ...worker,
          userId: worker.id
        }))
      });
    },
    [filteredUsers, onChange]
  );

  const handleSelectUser = useCallback<
    (selectedUser: CheckableUsersListItemUserType) => void
  >(
    (selectedUser) => {
      const newUsers = uniqWith(
        [...filteredUsers, { ...selectedUser, share: 0 }],
        (prevSelectedUser, m) => prevSelectedUser.id === m.id
      );

      const clients = filter(newUsers, (user) => user.client);

      const workers = filter(newUsers, (user) => !user.client);

      onChange({
        clients: map(clients, (client) => ({
          ...client,
          userId: client.id
        })),
        workers: map(workers, (worker) => ({
          ...worker,
          userId: worker.id
        }))
      });
    },
    [filteredUsers, onChange]
  );

  return (
    <InviteUsersModalBody
      invitedUserUuids={invitedUserUuids}
      selectedUsers={filteredUsers as UserAvatarLinkUser[]}
      selectedUserIds={map(filteredUsers, 'id')}
      onSelectUser={handleSelectUser}
      onDeselectUser={handleDeselectUser}
      withoutCheckAll
    />
  );
}

export default CreateSmartContractShareFormChangeUsers;
